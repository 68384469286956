.calendar-container {
    display: flex;
    flex-flow: row wrap;
    /*height: 1vh;*/
}

.calendar-heading {
    font-weight: bold;
}

.calendar-item-day {
    text-align: center;
}

.calendar-item {
    text-align: center;
    flex-basis: 14.2857142857%;
}
