a {
    color: inherit;
}

a:visited {
    color: inherit;
}

a:default {
    color: inherit;
}
