#dashboard-slider {
    position: relative;
    margin: 0 auto;
}

#dashboard-slider > * {
    animation: 24s autoPlay infinite linear;
}

#dashboard-slider img {
    position: absolute;
    left: 0;
    opacity: 0;
    width: 100%;
}

@keyframes autoPlay {
    0% {
        opacity: 0;
    }
    2.083333333% {
        opacity: 1;
    }
    16.6666667% {
        opacity: 1;
    }
    18.74% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

#dashboard-slider > *:nth-child(1) {
    animation-delay: 0s;
}

#dashboard-slider > *:nth-child(2) {
    animation-delay: 4s;
}

#dashboard-slider > *:nth-child(3) {
    animation-delay: 8s;
}

#dashboard-slider > *:nth-child(4) {
    animation-delay: 12s;
}

#dashboard-slider > *:nth-child(5) {
    animation-delay: 16s;
}

#dashboard-slider > *:nth-child(6) {
    animation-delay: 20s;
}
